@import '../../../../scss/theme-bootstrap';

$tabbed-block-z-index: 990;

.tabbed-block {
  @include content-container;
  margin: 0 auto;
  padding: 40px 0;
  &__overlay {
    position: fixed;
    #{$ldirection}: 0;
    top: 0;
    z-index: $tabbed-block-z-index - 1;
    height: 0;
    width: 100%;
    opacity: 0;
    background-color: $color-gray;
    cursor: pointer;
    transition: visibility 0.2s, opacity 0.2s;
    @include breakpoint($medium-up) {
      display: none;
    }
    &.open {
      visibility: visible;
      opacity: 0.75;
      height: 100%;
    }
  }
  &__wrapper {
    min-height: 50px;
    position: relative;
    padding: 0 20px;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0 0 0 20px);
      width: 100%;
      max-width: 720px;
    }
    @include breakpoint($landscape-up) {
      @include swap_direction(padding, 0 0 0 40px);
    }
    .tabbed-block.has-header & {
      min-height: 100px;
    }
  }
  &__header {
    @include swap_direction(margin, 0 10px 0 0);
    @include font--heading;
    font-size: 42px;
    line-height: 1;
    display: inline;
    text-transform: uppercase;
    color: $color-light-black;
    @include breakpoint($medium-up) {
      @include swap_direction(margin, 0 15px 0 0);
      font-size: 50px;
      line-height: 1;
    }
  }
  &__close-icon {
    display: none;
  }
  &__tabs-inner {
    display: inline;
  }
  &__tabs:not(.open) {
    .tabbed-block__tab.selected {
      @include icon('caret--down', after);
      @include swap_direction(padding, 0 20px 0 0);
      @include font--heading;
      font-size: 42px;
      line-height: 1;
      text-transform: uppercase;
      color: $color-light-black;
      position: relative;
      display: inline;
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 3px;
      cursor: pointer;
      transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
      @include breakpoint($medium-up) {
        font-size: 50px;
        line-height: 1;
      }
      &:hover {
        @include breakpoint($medium-up) {
          text-decoration-color: $color-neutral-gray;
          color: $color-neutral-gray;
        }
      }
      &::after {
        position: absolute;
        #{$rdirection}: 0;
        bottom: 20px;
        font-size: 8px;
        text-decoration: none;
        @include breakpoint($medium-up) {
          bottom: 25px;
        }
      }
    }
    .tabbed-block__tab:not(.selected) {
      display: none;
    }
  }
  &__tabs {
    display: inline;
    &.open {
      position: fixed;
      bottom: 0;
      #{$ldirection}: 0;
      z-index: $tabbed-block-z-index;
      width: 100%;
      padding: 60px 0 30px;
      background: $color-white;
      box-shadow: 0 4px 10px rgba(48, 48, 48, 0.1);
      @include breakpoint($medium-up) {
        position: absolute;
        bottom: auto;
        #{$ldirection}: auto;
        z-index: 1;
        max-width: 446px;
        padding: 30px 0;
      }
      &:hover .tabbed-block__tab {
        color: $color-neutral-gray;
      }
      .tabbed-block__close-icon {
        display: block;
        position: absolute;
        #{$rdirection}: 20px;
        top: 20px;
        z-index: 1;
        cursor: pointer;
        @include breakpoint($medium-up) {
          display: none;
        }
        svg {
          height: 16px;
          width: 16px;
        }
      }
      .tabbed-block__tab {
        @include font--heading;
        display: block;
        padding: 10px 40px 5px;
        font-size: 26px;
        line-height: 1.1;
        text-transform: uppercase;
        cursor: pointer;
        transition-property: color 0.2s ease-in-out, background 0.2s ease-in-out;
        &:hover {
          @include breakpoint($medium-up) {
            color: $color-light-black;
            background: $color-primary-beige;
          }
        }
        &.selected {
          color: $color-light-black;
          background: $color-primary-beige;
        }
      }
    }
  }
  &__content {
    margin: 30px 0 0;
    @include breakpoint($large-up) {
      margin: 15px 0 0;
      @if $cr22 {
        padding: 0;
       } @else {
        padding: 0 40px;
      }
    }
  }
  &__content-item {
    height: 0;
    width: 100%;
    overflow: hidden;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    &.selected {
      height: auto;
      overflow: visible;
      opacity: 1;
    }
  }
  &.dropdown-align-center {
    .tabbed-block__tabs.open {
      position: absolute;
      top: 100%;
      bottom: unset;
    }
    .tabbed-block__subheader {
      display: none;
    }
    .tabbed-block__header:has(+ .open) {
      .tabbed-block__subheader {
        &.active {
          margin-#{$ldirection}: 10px;
          display: block;
        }
      }
    }
  }
}
